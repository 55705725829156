import { Link } from 'gatsby'
import React from 'react'
import { Container } from '~/common/Container/Container'
import { Layout } from '~/common/Layout/Layout'
import { SEO } from '~/common/Seo/Seo'
import { Box } from '~/ui/core/Box/Box'
import { Button } from '~/ui/core/Button/Button'
import { Flex } from '~/ui/core/Flex/Flex'
import { Text } from '~/ui/core/Text/Text'

const NotFoundPage = () => (
  <Layout>
    <Box
      backgroundColor="blackDark"
      css={{
        padding: '$xl $base',
        '@lg': {
          py: '$5xl',
        },
      }}
    >
      <Container>
        <Flex
          direction="column"
          alignItems="center"
          justifyContent="center"
          gap="xl"
        >
          <Text
            as="h1"
            align="center"
            weight="bold"
          >
            Page not found
          </Text>
          <Text as="p">
            Sorry, the page you requested was not found.{' '}
            <span
              role="img"
              aria-label="Pensive emoji"
            >
              😔
            </span>
          </Text>
          <Button
            accent="cyan"
            asGatsbyLink
            to="/"
          >
            Back to home
          </Button>
        </Flex>
      </Container>
    </Box>
  </Layout>
)

export default NotFoundPage

export const Head = () => <SEO title="Page Not Found" />
